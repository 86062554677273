@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Montserrat:wght@200;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Ceviche+One');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #ffffff;
}
@layer base {
  @font-face {
    font-family: DSEG font;
    font-weight: 400;
    src: url("./fonts/dseg-font.woff") format("woff");
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.fr-cc {
  @apply flex flex-row justify-center items-center;
}
.fr-sc {
  @apply flex flex-row justify-start items-center;
}
.fc-cc {
  @apply flex flex-col justify-center items-center;
}
.fc-sc {
  @apply flex flex-col justify-start items-center;
}

.bg-pos-1 {
  background-color: #fee912;
  color: black;
}
.bg-pos-2 {
  background-color: #e4e4e4;
  color: black;
}
.bg-pos-3 {
  background-color: #b09c75;
  color: black;
}

.html-overlay {
  @apply select-none;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.img-obey-cont {
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@keyframes pulse {
  0%,
  100% {
    scale: 1.3;
  }
  50% {
    scale: 0.9;
  }
}

.pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

#countdown1 {
  font-size: 9rem;
  margin: 50px;
  position: relative;
  color: aquamarine;
  animation: glitch 1s linear infinite, grow-font 1s ease-in-out infinite;
  font-family: 'Ceviche One';
  height: 200px;
  -webkit-text-stroke: 0.2rem blue;
}
@keyframes glitch {
  0% {
    transform: translateY(0);

  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes grow-font {
  0% {
    font-size: 1rem;
  }
  50% {
    font-size: 9rem;
  }
  100% {
    font-size: 9rem;
  }
}

.resp-text--4 {
  @apply xs:text-[0.35rem] lg:text-[0.6rem];
  a: b;
}
.resp-text--3 {
  @apply xs:text-[0.4rem] lg:text-[0.7rem];
  a: b;
}
.resp-text--2 {
  @apply xs:text-[0.45rem] lg:text-[0.8rem];
  a: b;
}
.resp-text--1 {
  @apply xs:text-[0.5rem] lg:text-[0.9rem];
  a: b;
}
.resp-text-0 {
  @apply xs:text-[0.55rem] lg:text-[1rem];
  a: b;
}
.resp-text-1 {
  @apply xs:text-[0.6rem] lg:text-[1.2rem];
  a: b;
}
.resp-text-2 {
  @apply xs:text-[0.7rem] lg:text-[1.5rem];
  a: b;
}
.resp-text-3 {
  @apply xs:text-[0.8rem] lg:text-[2rem];
  a: b;
}
.resp-p-1 {
  @apply xs:p-0.5 lg:p-1;
  a: b;
}
.resp-p-2 {
  @apply xs:p-1 lg:p-2;
  a: b;
}
.resp-p-3 {
  @apply xs:p-1.5 lg:p-3;
  a: b;
}
.resp-p-4 {
  @apply xs:p-2 lg:p-4;
  a: b;
}
.resp-px-1 {
  @apply xs:px-0.5 lg:px-1;
  a: b;
}
.resp-px-2 {
  @apply xs:px-1 lg:px-2;
  a: b;
}
.resp-px-3 {
  @apply xs:px-1.5 lg:px-3;
  a: b;
}
.resp-px-4 {
  @apply xs:px-2 lg:px-4;
  a: b;
}
.resp-py-1 {
  @apply xs:py-0.5 lg:py-1;
  a: b;
}
.resp-py-2 {
  @apply xs:py-1 lg:py-2;
  a: b;
}
.resp-py-3 {
  @apply xs:py-1.5 lg:py-3;
  a: b;
}
.resp-py-4 {
  @apply xs:py-2 lg:py-4;
  a: b;
}
.resp-m-1 {
  @apply xs:m-0.5 lg:m-1;
  a: b;
}
.resp-m-2 {
  @apply xs:m-1 lg:m-2;
  a: b;
}
.resp-m-3 {
  @apply xs:m-1.5 lg:m-3;
  a: b;
}
.resp-m-4 {
  @apply xs:m-2 lg:m-4;
  a: b;
}
.resp-mx-1 {
  @apply xs:mx-0.5 lg:mx-1;
  a: b;
}
.resp-mx-2 {
  @apply xs:mx-1 lg:mx-2;
  a: b;
}
.resp-mx-3 {
  @apply xs:mx-1.5 lg:mx-3;
  a: b;
}
.resp-mx-4 {
  @apply xs:mx-2 lg:mx-4;
  a: b;
}
.resp-my-1 {
  @apply xs:my-0.5 lg:my-1;
  a: b;
}
.resp-my-2 {
  @apply xs:my-1 lg:my-2;
  a: b;
}
.resp-my-3 {
  @apply xs:my-1.5 lg:my-3;
  a: b;
}
.resp-my-4 {
  @apply xs:my-2 lg:my-4;
  a: b;
}
.resp-gap-1 {
  @apply xs:gap-0.5 lg:gap-1;
  a: b;
}
.resp-gap-2 {
  @apply xs:gap-1 lg:gap-2;
  a: b;
}
.resp-gap-3 {
  @apply xs:gap-1.5 lg:gap-3;
  a: b;
}
.resp-gap-4 {
  @apply xs:gap-2 lg:gap-4;
  a: b;
}

tr.tdrow {
  td {
    @apply border-b border-slate-600 resp-px-2 resp-py-1 text-left xs:min-w-[1rem] lg:min-w-[5rem];
    foo: bar;
  }
}

tr.thintdrow {
  td {
    @apply border-b border-slate-600 resp-px-2 resp-py-1 text-left xs:min-w-[1rem] lg:min-w-[2rem];
    foo: bar;
  }
}

.html-overlay {
  @apply select-none;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: inherit;
  height: inherit;
  z-index: 100;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-anim {
  animation: spin 1s infinite;
}
